import axios, { AxiosRequestConfig } from 'axios'
import { baseUrl, envIsDev, nbWebAccessKey, nbWebSecretKey } from '@/helper/env'
import { contentTypeEnum } from '@/interceptors/request/set-content-type-and-data'
// import ResponseInterceptors from './interceptors/response'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { postMemberAuthRefreshToken } from '@/apis/user'
import { baseUserStore } from '@/store/user'
import { setToken } from '@/helper/auth'
import { tokenPreRefreshValue } from '@/constants/auth'
import CryptoJS from 'crypto-js'
import responseHandler from './interceptors/response/response-handler'
import RequestInterceptors from './interceptors/request'
/**
 * 统一请求参数设置
 */

export type CustomRequestConfig = {
  path?: string
  signature?: string
  contentType?: contentTypeEnum
  isUseTuleApi?: boolean

  signedData?: any
  /** 为兼容某部分接口没按照特定格式返回 */
  needAllRes?: boolean
}

/**
 *
 */
export type MarkcoinRequestConfig = AxiosRequestConfig & CustomRequestConfig

/**
 * 统一请求函数定义
 */
export type MarkcoinRequest<Q = any, P = any> = (params: Q) => Promise<MarkcoinResponse<P>>
export type BaseMarkcoinResponse<R = any> = { message: string; data: R; code: number; msg?: string }

/**
 * 统一返回定义
 */
export type MarkcoinResponse<P = any> = {
  isOk: boolean
  message?: string
  data?: P
  code?: number
  msg?: string
}

const axiosRequest = axios.create({
  baseURL: envIsDev ? baseUrl : baseUrl,
  // baseURL: 'https://im-admin-staging.jklkjnqscc.com/', // 测试地址
  // baseURL: 'https://console-api.talkblink.com',
  withCredentials: false,
  timeout: 30 * 1000,
})

// 注册 API 请求拦截
RequestInterceptors.forEach(interceptor => {
  axiosRequest.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected)
})

// // 注册 API 返回拦截
// ResponseInterceptors.forEach(interceptor => {
//   axiosRequest.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected)
// })
function refreshAuthLogic(failedRequest) {
  const userStore = baseUserStore.getState()
  const tokenObj = userStore.token
  return postMemberAuthRefreshToken({ refreshToken: tokenObj?.refreshToken as string }).then(freshRes => {
    const freshResVal = freshRes?.data
    if (freshResVal?.accessToken) {
      setToken(freshResVal)
      failedRequest.response.config.headers.Authorization = freshResVal?.accessToken
    }
    return Promise.resolve()
  })
}
// /** 网关签名算法 */
// const hmacSHA256 = (params, secretKey) => {
//   return CryptoJS.HmacSHA256(params, secretKey).toString(CryptoJS.enc.Hex)
// }
// // 添加请求拦截器
// axiosRequest.interceptors.request.use(
//   function (config) {
//     const date = Date.now()
//     const apiUrl = config.url
//     const signHeadersStr = 'x-custom-a:test'
//     const httpMethod = config.method?.toUpperCase()
//     const userAgent = `User-Agent:${navigator?.userAgent}`
//     let params = ''
//     const paramsUrl = config?.params
//     if (paramsUrl) {
//       params = Object.keys(paramsUrl)
//         .map(key => `${key}=${paramsUrl[key]}`)
//         .join('&')
//     }
//     const paramsStr = config?.params ? params : ''
//     const msg = `${httpMethod}\n${apiUrl}\n${
//       paramsStr ? `${paramsStr}\n` : ''
//     }${nbWebAccessKey}\n${date}\n${userAgent}\n${signHeadersStr}\n`
//     if (config?.headers) {
//       config.headers['nb-date'] = date
//       config.headers.businessId = '1'
//       config.headers['x-custom-a'] = 'test'
//       config.headers['nb-mac-access-key'] = nbWebAccessKey
//       config.headers['nb-mac-sign'] = hmacSHA256(msg, nbWebSecretKey)
//     }
//     return config
//   },
//   function (error) {
//     // 对请求错误的处理
//     return Promise.reject(error)
//   }
// )
createAuthRefreshInterceptor(axiosRequest, refreshAuthLogic, {
  shouldRefresh: () => {
    const date = Date.now()
    const userStore = baseUserStore.getState()
    const tokenObj = userStore.token
    // 区间内可以刷新，大于指定时间，且小于过期时间
    const refreshTokenExpireTime = Number(tokenObj?.refreshTokenExpireTime)
    if (refreshTokenExpireTime) {
      // 刷新 token 未过期
      if (date < refreshTokenExpireTime) {
        // 要提前一小时刷新
        return date + tokenPreRefreshValue >= refreshTokenExpireTime
      }
      return false
    }
    return false
  },
})

const Request: MarkcoinRequest = (config: MarkcoinRequestConfig) => {
  // 返回结果或者任何异常都会被 response Interceptor 拦截
  return axiosRequest
    .request<any, MarkcoinResponse>(config)
    .then(res => {
      return responseHandler.onFulfilled(res)
    })
    .catch(err => {
      return responseHandler.onRejected(err)
    })
}

export default Request
